import { fork } from 'redux-saga/effects';

import { downloadsSaga } from './downloads';
import { fieldsSaga } from './fields';
import { filtersSaga } from './filters';

export function* datamodelSaga() {
  yield fork(downloadsSaga);
  yield fork(filtersSaga);
  yield fork(fieldsSaga);
}
