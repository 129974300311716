import { call, put, select } from 'redux-saga/effects';

import { Authentication } from '@alkem/sdk-dashboard';

import type { RootState } from 'src/store/reducers';
import { toSerializableError } from 'src/utils/errors';
import { takeLatestSafe } from 'src/utils/sagas';

import { LOGGING_IN, loggedInAction, loginErrorAction } from '../actions/login';
import { fetchUserAction } from '../actions/user';
import { fetchUserToken } from '../api';

export function* loginSaga() {
  yield takeLatestSafe(LOGGING_IN, loggingInSaga);
}

function* loggingInSaga() {
  try {
    const { username, password, rememberMe } = yield select(
      (state: RootState) => state.login,
    );
    const { accessToken, refreshToken } = yield call(
      fetchUserToken,
      username,
      password,
    );
    Authentication.storeTokensAfterLogin(accessToken, refreshToken, rememberMe);
    yield put(loggedInAction());
    yield put(fetchUserAction({ withRetry: false }));
  } catch (error: any) {
    console.error(error);
    yield put(loginErrorAction(toSerializableError(error)));
  }
}
