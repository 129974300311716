import { createReducer } from '@reduxjs/toolkit';

import {
  fetchingFieldsAction,
  updateFieldsDataAction,
} from '../actions/fields';
import {
  fetchReferentialAction,
  resetReferentialAction,
  selectReferentialAction,
  updateFiltersDataAction,
  updateReferentialAction,
} from '../actions/filters';
import type { DatamodelFieldsStateImmutable } from '../types';

export const datamodelFieldsReducerKey = 'datamodelFields';

export const datamodelFieldsInitialState: DatamodelFieldsStateImmutable = {
  isFetching: false,
  list: [],
  total: 0,
  entities: { packagingTypes: {} },
  referential: { selected: null, isFetching: false, list: null },
};

export const datamodelFieldsReducer =
  createReducer<DatamodelFieldsStateImmutable>(
    datamodelFieldsInitialState,
    (builder) => {
      builder
        .addCase(fetchingFieldsAction, (state, action) => {
          state.isFetching = action.payload;
        })
        .addCase(updateFieldsDataAction, (state, action) => {
          state.list = action.payload.fields;
          state.total = action.payload.total;
        })
        .addCase(updateFiltersDataAction, (state, action) => {
          state.entities.packagingTypes = action.payload.packaging_types.reduce(
            (acc, pkg) => {
              acc[pkg.id] = pkg.label;
              return acc;
            },
            {} as { [id: number]: string },
          );
        })
        .addCase(selectReferentialAction, (state, action) => {
          state.referential.selected = action.payload;
        })
        .addCase(fetchReferentialAction, (state) => {
          state.referential.isFetching = true;
        })
        .addCase(updateReferentialAction, (state, action) => {
          state.referential.isFetching = false;
          state.referential.list = action.payload;
        })
        .addCase(resetReferentialAction, (state) => {
          state.referential = {
            selected: null,
            isFetching: false,
            list: null,
          };
        });
    },
  );
