import classnames from 'classnames';
import type { Immutable } from 'immer';
import type { TreeProps } from 'rc-tree';
import RcTree from 'rc-tree';
import { useState } from 'react';

import type { DataNode } from 'src/utils/tree';

import styles from './tree.module.scss';

interface TreePropsType<TreeDataType extends DataNode | Immutable<DataNode>> {
  className?: string;
  data: TreeDataType[] | Immutable<TreeDataType[]>;
  expandedIds?: TreeDataType['key'][];
  filter?: TreeProps<TreeDataType>['filterTreeNode'];
  height?: number;
  itemHeight?: TreeProps<TreeDataType>['itemHeight'];
  onExpand?: TreeProps<TreeDataType>['onExpand'];
  onSelect: TreeProps<TreeDataType>['onSelect'];
  virtual?: TreeProps<TreeDataType>['virtual'];
}

export function Tree<DataType extends DataNode | Immutable<DataNode>>({
  className,
  data,
  expandedIds,
  filter,
  height = 300,
  itemHeight = 22,
  onExpand,
  onSelect,
  virtual = true,
}: TreePropsType<DataType>) {
  const isExpandedControlled = !!expandedIds;
  const [expanded, setExpanded] = useState<DataType['key'][]>([]);
  return (
    <RcTree
      checkable={false}
      checkStrictly
      className={classnames(styles.tree, className)}
      draggable={false}
      expandedKeys={isExpandedControlled ? expandedIds : expanded}
      filterTreeNode={filter}
      height={height}
      itemHeight={itemHeight}
      multiple={false}
      onExpand={(expandedKeys, event) => {
        if (isExpandedControlled) {
          if (onExpand) {
            onExpand(expandedKeys, event);
          }
        } else {
          setExpanded(expandedKeys);
        }
      }}
      onSelect={(selectedKeys, event) => {
        if (onSelect) {
          onSelect(selectedKeys, event);
        }
      }}
      selectable
      showIcon={false}
      showLine={false}
      switcherIcon={({ expanded, isLeaf, eventKey }) => (
        <i
          data-testid={`chevron-option-${eventKey}`}
          className={classnames(
            'mdi',
            isLeaf
              ? 'mdi-blank'
              : expanded
                ? 'mdi-chevron-down'
                : 'mdi-chevron-right',
          )}
        />
      )}
      treeData={data as DataType[]}
      virtual={virtual}
    />
  );
}
