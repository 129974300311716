const toDate = (dateOrString: string | Date) =>
  typeof dateOrString === 'string' ? new Date(dateOrString) : dateOrString;

export const toISODateString = (
  dateOrString: string | Date | null | undefined,
): string | null => {
  if (!dateOrString) {
    return null;
  }
  return toDate(dateOrString).toISOString().split('T')[0];
};

export const toLocaleDateString = (
  dateOrString: string | Date | null | undefined,
  locales?: string | string[],
): string | null => {
  if (!dateOrString) {
    return null;
  }
  return toDate(dateOrString).toLocaleDateString(locales);
};
