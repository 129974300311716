import '@ungap/global-this';
import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { ErrorBoundary } from 'react-error-boundary';
import { Provider } from 'react-redux';

import { Authentication } from '@alkem/sdk-dashboard';

import './index.scss';
import { store } from './store';

import { App } from './App';
import { FallbackComponent } from './components/error-boundary/fallback';
import { initDataDogRUM } from './utils/datadog';
import { reportWebVitals } from './utils/reportWebVitals';

initDataDogRUM();
Authentication.initTokenStorageOnLoad();

const root = createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <StrictMode>
    <ErrorBoundary FallbackComponent={FallbackComponent}>
      <Provider store={store}>
        <App />
      </Provider>
    </ErrorBoundary>
  </StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
