export const filterBody = <T>(body: T): T =>
  Object.entries(body as object).reduce((acc, [key, value]) => {
    if (
      value == null ||
      value === '' ||
      (Array.isArray(value) && value.length === 0)
    ) {
      return acc;
    }
    acc[key] = value;
    return acc;
  }, {} as T);
