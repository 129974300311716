import { createReducer } from '@reduxjs/toolkit';

import {
  loggedInAction,
  loggingInAction,
  loginErrorAction,
  showLoginAction,
  updatePasswordAction,
  updateRememberMeAction,
  updateUsernameAction,
} from '../actions/login';
import { LoginStateImmutable } from '../types';

export const loginReducerKey = 'login';

export const loginInitialState: LoginStateImmutable = {
  isLoggingIn: false,
  password: '',
  rememberMe: false,
  showLogin: false,
  username: '',
};

export const loginReducer = createReducer<LoginStateImmutable>(
  loginInitialState,
  (builder) => {
    builder
      .addCase(updateUsernameAction, (state, action) => {
        state.username = action.payload;
      })
      .addCase(updatePasswordAction, (state, action) => {
        state.password = action.payload;
      })
      .addCase(updateRememberMeAction, (state, action) => {
        state.rememberMe = action.payload;
      })
      .addCase(loggingInAction, (state) => {
        state.isLoggingIn = true;
      })
      .addCase(loggedInAction, (state) => {
        state.isLoggingIn = false;
        state.showLogin = false;
      })
      .addCase(loginErrorAction, (state, action) => {
        state.isLoggingIn = false;
        state.loginError = action.payload;
      })
      .addCase(showLoginAction, (state, action) => {
        state.showLogin = action.payload;
      });
  },
);
