import type { Immutable } from 'immer';

import type { DataNode } from 'src/utils/tree';

import { TreeSelect, TreeSelectProps } from './tree-select';

export function Select<DataType extends DataNode | Immutable<DataNode>>(
  props: TreeSelectProps<DataType>,
) {
  return <TreeSelect {...props} flat />;
}
