import { configureStore } from '@reduxjs/toolkit';
import {
  EqualityFn,
  useDispatch as reduxUseDispatch,
  useSelector as reduxUseSelector,
} from 'react-redux';
import ReduxSaga from 'redux-saga';

import { RootState, rootReducer } from './reducers';
import { rootSaga } from './sagas';

const sagaMiddleware =
  typeof (ReduxSaga as any).default === 'function'
    ? (ReduxSaga as any).default()
    : ReduxSaga();

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(sagaMiddleware),
});

sagaMiddleware.run(rootSaga);

export type Dispatch = typeof store.dispatch;
export const useDispatch = () => reduxUseDispatch<Dispatch>();

export function useSelector<Selected = unknown>(
  selector: (state: RootState) => Selected,
  equalityFn?: EqualityFn<Selected> | undefined,
): Selected {
  return reduxUseSelector<RootState, Selected>(selector, equalityFn);
}
