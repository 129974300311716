import { useEffect, useState } from 'react';

import { TextInput } from 'src/components/text/input';
import { useDebounce } from 'src/utils/hooks/use-debounce';

export function SearchFilter({
  query = '',
  onChange,
  delay = 800,
}: {
  query?: string;
  onChange: (query: string) => void;
  delay?: number;
}) {
  const [value, setValue] = useState<string>(query);

  useDebounce(value, delay, () => {
    onChange(value);
  });

  useEffect(() => {
    if (query !== value) {
      setValue(query);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  return (
    <TextInput value={value} onChange={setValue} testid="search-filter" block />
  );
}
