import { createReducer } from '@reduxjs/toolkit';

import {
  fetchUserAction,
  storeUserAction,
  userErrorAction,
} from '../actions/user';
import { UserStateImmutable } from '../types';

export const userReducerKey = 'user';

export const userInitialState: UserStateImmutable = {
  isFetchingUser: true,
};

export const userReducer = createReducer<UserStateImmutable>(
  userInitialState,
  (builder) => {
    builder
      .addCase(fetchUserAction, (state) => {
        state.isFetchingUser = true;
      })
      .addCase(storeUserAction, (state, action) => {
        state.data = action.payload;
        state.isFetchingUser = false;
      })
      .addCase(userErrorAction, (state, action) => {
        state.error = action.payload;
      });
  },
);
