export const dedupe = <T>(list: Array<T> | ReadonlyArray<T>) => [
  ...new Set(list),
];

export const add = <T>(list: Array<T> | ReadonlyArray<T>, ...items: Array<T>) =>
  dedupe([...list, ...items]);

export const remove = <T>(
  list: Array<T> | ReadonlyArray<T>,
  ...items: Array<T>
) => list.filter((i) => !items.includes(i));

export const mapRepeat = <T>(count: number, callback: (i: number) => T) =>
  Array(count)
    .fill(0)
    .map((_, i) => callback(i));
