import { call, put } from 'redux-saga/effects';

import { Authentication, ResponseWithData, User } from '@alkem/sdk-dashboard';

import { identifyDataDogSession } from 'src/utils/datadog';
import { toSerializableError } from 'src/utils/errors';
import { takeLatestSafe } from 'src/utils/sagas';

import { showLoginAction } from '../actions/login';
import {
  FETCH_USER,
  fetchUserAction,
  storeUserAction,
  userErrorAction,
} from '../actions/user';
import { fetchUser } from '../api';

export function* userSaga() {
  yield takeLatestSafe(FETCH_USER, fetchUserSaga);
}

function* fetchUserSaga({
  payload: { withRetry },
}: ReturnType<typeof fetchUserAction>) {
  try {
    const response: ResponseWithData<User> = yield call(fetchUser);
    const user = response.data.data;
    yield call(identifyDataDogSession, user);
    yield put(storeUserAction(user));
  } catch (error: any) {
    if (error.status >= 400 && error.status < 500) {
      if (withRetry) {
        Authentication.clearTokensAfterLogout();
        Authentication.initTokenStorageOnLoad();
        yield put(fetchUserAction({ withRetry: false }));
        return;
      }
      yield put(showLoginAction(true));
    } else {
      console.error(error);
      yield put(userErrorAction(toSerializableError(error)));
    }
  }
}
