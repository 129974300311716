import { createAction } from '@reduxjs/toolkit';

import type { LoginState } from '../types';

export const UPDATE_USERNAME = 'login/update-username';
export const UPDATE_PASSWORD = 'login/update-password';
export const UPDATE_REMEMBER_ME = 'login/update-remember-me';
export const LOGGING_IN = 'login/logging-in';
export const LOGGED_IN = 'login/logged-in';
export const LOGIN_ERROR = 'login/error';
export const SHOW_LOGIN = 'login/show';

export const updateUsernameAction =
  createAction<LoginState['username']>(UPDATE_USERNAME);
export const updatePasswordAction =
  createAction<LoginState['password']>(UPDATE_PASSWORD);
export const updateRememberMeAction =
  createAction<LoginState['rememberMe']>(UPDATE_REMEMBER_ME);
export const loggingInAction = createAction(LOGGING_IN);
export const loggedInAction = createAction(LOGGED_IN);
export const loginErrorAction =
  createAction<LoginState['loginError']>(LOGIN_ERROR);
export const showLoginAction =
  createAction<LoginState['showLogin']>(SHOW_LOGIN);
