import classnames from 'classnames';
import { useState } from 'react';

import { SerializableError } from '../types';

import styles from './error.module.scss';

export const LoginError = ({ error }: { error: SerializableError }) => {
  const [showDetails, setDetails] = useState(false);
  return (
    <div className={styles.loginError} data-testid="LoginError">
      <i className={classnames('mdi mdi-alert', styles.icon)} />
      <div className={styles.body}>
        {(error as any).data?.data?.message ||
          (error as any).data?.message ||
          error.message}
        {error.stack ? (
          showDetails ? (
            <div className={styles.stack}>
              <pre>{error.stack}</pre>
            </div>
          ) : (
            <button
              className={styles.detailsButton}
              onClick={() => setDetails(true)}
            >
              Show details
            </button>
          )
        ) : null}
      </div>
    </div>
  );
};
