import classNames from 'classnames';
import { useState } from 'react';

import { DatePicker } from './date-picker';
import styles from './date-range-picker.module.scss';

type DateRange = [undefined | Date, undefined | Date];

export function DateRangePicker({
  className,
  onChange,
  ...props
}: {
  className?: string;
  from?: Date;
  to?: Date;
  onChange?: (range: [undefined | Date, undefined | Date]) => void;
}) {
  const [range, setRange] = useState<DateRange>([undefined, undefined]);
  const isControlled = 'from' in props || 'to' in props;
  const fromDate = isControlled ? props.from : range[0];
  const toDate = isControlled ? props.to : range[1];
  return (
    <div
      className={classNames(styles.dateRange, className)}
      data-testid="date-range-picker"
    >
      <div className={styles.dateFrom} data-testid="date-range-picker-from">
        <span className={styles.label}>From:</span>
        <DatePicker
          name="date-picker-from-input"
          value={fromDate}
          maxDate={toDate}
          onChange={(value) => {
            if (!isControlled) {
              setRange(([, to]) => [value, to]);
            }
            if (onChange) {
              onChange([value, toDate]);
            }
          }}
        />
      </div>
      <div className={styles.dateTo} data-testid="date-range-picker-to">
        <span className={styles.label}>To:</span>
        <DatePicker
          name="date-picker-to-input"
          value={toDate}
          minDate={fromDate}
          onChange={(value) => {
            if (!isControlled) {
              setRange(([from]) => [from, value]);
            }
            if (onChange) {
              onChange([fromDate, value]);
            }
          }}
        />
      </div>
    </div>
  );
}
