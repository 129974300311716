import classnames from 'classnames';
import {
  ChangeEvent,
  DetailedHTMLProps,
  InputHTMLAttributes,
  ReactNode,
  useState,
} from 'react';

import styles from './checkbox.module.scss';

interface Props
  extends Omit<
    DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>,
    'onChange' | 'children'
  > {
  children?: ReactNode;
  labelClassname?: string;
  onChange: (isChecked: boolean, event: ChangeEvent<HTMLInputElement>) => void;
}

export const Checkbox = ({
  checked,
  children,
  labelClassname,
  onChange,
  ...props
}: Props) => {
  const [hasFocus, setFocus] = useState(false);
  return (
    <label
      className={classnames(
        styles.label,
        hasFocus && styles.labelFocus,
        props.disabled && styles.labelDisabled,
        labelClassname,
      )}
    >
      <input
        {...props}
        type="checkbox"
        className={classnames(styles.checkbox, props.className)}
        checked={checked}
        onChange={(event) => {
          if (!props.disabled) {
            onChange(event.target.checked, event);
          }
        }}
        onFocus={(event) => {
          setFocus(true);
          if (props.onFocus) {
            props.onFocus(event);
          }
        }}
        onBlur={(event) => {
          setFocus(false);
          if (props.onBlur) {
            props.onBlur(event);
          }
        }}
      />
      <i
        className={classnames(
          'mdi',
          checked ? 'mdi-checkbox-marked' : 'mdi-checkbox-blank-outline',
          checked && styles.iconChecked,
          styles.icon,
        )}
      />
      {children ? <span className={styles.text}>{children}</span> : null}
    </label>
  );
};
