import classNames from 'classnames';
import { useState } from 'react';

import { FieldSamplesType } from '../../../types';

import styles from './samples.module.scss';

enum SampleType {
  JSON = 'json',
  XML = 'xml',
}

export function DatamodelFieldSamples({
  samples,
}: {
  samples: FieldSamplesType;
}) {
  const [sampleType, setSampleType] = useState<SampleType>(SampleType.JSON);
  return (
    <>
      <div className={styles.sampleTypes}>
        <span className={styles.samplesTitle}>Samples: </span>
        <button
          className={classNames(
            styles.sampleButton,
            sampleType === SampleType.JSON && styles.sampleButtonSelected,
          )}
          onClick={() => {
            setSampleType(SampleType.JSON);
          }}
        >
          JSON
        </button>
        <button
          className={classNames(
            styles.sampleButton,
            sampleType === SampleType.XML && styles.sampleButtonSelected,
          )}
          onClick={() => {
            setSampleType(SampleType.XML);
          }}
        >
          XML
        </button>
      </div>
      <pre className={styles.samplesContent}>{samples[sampleType]}</pre>
    </>
  );
}
