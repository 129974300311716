import { createAction } from '@reduxjs/toolkit';

import { SerializableError } from 'src/utils/errors';

export const DOWNLOAD_ALL_REFERENTIALS = 'datamodel/download-all-referentials';
export const DOWNLOADING_ALL_REFERENTIALS =
  'datamodel/downloading-all-referentials';
export const DOWNLOAD_ALL_REFERENTIALS_ERROR =
  'datamodel/download-all-referentials-error';

export const DOWNLOAD_ALL_JSON_SAMPLES = 'datamodel/download-all-json-samples';
export const DOWNLOADING_ALL_JSON_SAMPLES =
  'datamodel/downloading-all-json-samples';
export const DOWNLOAD_ALL_JSON_SAMPLES_ERROR =
  'datamodel/download-all-json-samples-error';

export const DOWNLOAD_ALL_XML_SAMPLES = 'datamodel/download-all-xml-samples';
export const DOWNLOADING_ALL_XML_SAMPLES =
  'datamodel/downloading-all-xml-samples';
export const DOWNLOAD_ALL_XML_SAMPLES_ERROR =
  'datamodel/download-all-xml-samples-error';

export const downloadAllReferentialsAction = createAction(
  DOWNLOAD_ALL_REFERENTIALS,
);
export const downloadingAllReferentialsAction = createAction<boolean>(
  DOWNLOADING_ALL_REFERENTIALS,
);
export const downloadAllReferentialsErrorAction =
  createAction<SerializableError>(DOWNLOAD_ALL_REFERENTIALS_ERROR);

export const downloadAllJsonSamplesAction = createAction(
  DOWNLOAD_ALL_JSON_SAMPLES,
);
export const downloadingAllJsonSamplesAction = createAction<boolean>(
  DOWNLOADING_ALL_JSON_SAMPLES,
);
export const downloadAllJsonSamplesErrorAction =
  createAction<SerializableError>(DOWNLOAD_ALL_JSON_SAMPLES_ERROR);

export const downloadAllXmlSamplesAction = createAction(
  DOWNLOAD_ALL_XML_SAMPLES,
);
export const downloadingAllXmlSamplesAction = createAction<boolean>(
  DOWNLOADING_ALL_XML_SAMPLES,
);
export const downloadAllXmlSamplesErrorAction = createAction<SerializableError>(
  DOWNLOAD_ALL_XML_SAMPLES_ERROR,
);
