import { combineReducers } from 'redux';

import { authReducers } from 'src/modules/auth/reducers';
import { datamodelReducers } from 'src/modules/datamodel/reducers';

import { RESET_APP } from './actions';

const reducers = combineReducers({
  ...authReducers,
  ...datamodelReducers,
});

export const rootReducer: typeof reducers = (state, action) => {
  if (action.type === RESET_APP) {
    return reducers(undefined, action);
  }
  return reducers(state, action);
};

export type RootState = ReturnType<typeof rootReducer>;
