import { ReactNode, useMemo } from 'react';
import { createPortal } from 'react-dom';

import styles from './modal.module.scss';

export function Modal({
  children,
  title = '',
  onClose,
}: {
  children: ReactNode;
  title?: ReactNode;
  onClose: () => void;
}) {
  const modalRootEl = useMemo(
    () => document.getElementById('modal-root') as HTMLElement,
    [],
  );

  return createPortal(
    <div
      className={styles.overlay}
      data-testid="modal-overlay"
      onClick={onClose}
    >
      <div
        className={styles.modal}
        onClick={(event) => event.stopPropagation()}
      >
        <div className={styles.header}>
          <div className={styles.title}>{title}</div>
          <button
            aria-label="Close modal"
            className={styles.closeButton}
            data-testid="modal-close-button"
            onClick={onClose}
          >
            <i className="mdi mdi-close" />
          </button>
        </div>
        <div className={styles.body}>{children}</div>
      </div>
    </div>,
    modalRootEl,
  );
}
