import { useEffect } from 'react';

import { useDispatch, useSelector } from './store';

import styles from './App.module.scss';
import { fetchUserAction } from './modules/auth/actions/user';
import { LoginPage } from './modules/auth/components/login';
import { LogoutAction } from './modules/auth/components/logout';
import { DatamodelDocumentation } from './modules/datamodel';

export function App() {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.data);
  const showLogin = useSelector((state) => state.login.showLogin);

  useEffect(() => {
    dispatch(fetchUserAction({ withRetry: true }));
  }, [dispatch]);

  return (
    <div className={styles.app} data-testid="app">
      {user ? (
        <>
          <LogoutAction />
          <DatamodelDocumentation />
        </>
      ) : showLogin ? (
        <LoginPage />
      ) : null}
    </div>
  );
}
