import { createAction } from '@reduxjs/toolkit';

import type { UserState } from '../types';

export const FETCH_USER = 'user/fetch';
export const STORE_USER = 'user/store';
export const USER_ERROR = 'user/error';

export const fetchUserAction = createAction<{ withRetry: boolean }>(FETCH_USER);
export const storeUserAction = createAction<UserState['data']>(STORE_USER);
export const userErrorAction = createAction<UserState['error']>(USER_ERROR);
