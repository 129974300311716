import { ApiClient, getServiceUrl } from '@alkem/sdk-dashboard';

export const fieldClient = () =>
  new ApiClient(getServiceUrl('field'), {
    acceptLanguage: 'en',
  });

export const coreClient = () =>
  new ApiClient(getServiceUrl('core'), {
    acceptLanguage: 'en',
  });
