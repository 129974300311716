import createDOMPurify from 'dompurify';
import type { DetailedHTMLProps, HTMLAttributes } from 'react';

export const { sanitize } = createDOMPurify(window);

interface DivPropsType
  extends Omit<
    DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>,
    'children'
  > {
  children: string | null | undefined;
}

export const HTMLDiv = ({ children, ...props }: DivPropsType) =>
  children ? (
    <div {...props} dangerouslySetInnerHTML={{ __html: sanitize(children) }} />
  ) : null;
