import {
  datamodelDownloadsReducer,
  datamodelDownloadsReducerKey,
} from './downloads';
import { datamodelFieldsReducer, datamodelFieldsReducerKey } from './fields';
import { datamodelFiltersReducer, datamodelFiltersReducerKey } from './filters';

export const datamodelReducers = {
  [datamodelDownloadsReducerKey]: datamodelDownloadsReducer,
  [datamodelFieldsReducerKey]: datamodelFieldsReducer,
  [datamodelFiltersReducerKey]: datamodelFiltersReducer,
};
