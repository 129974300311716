export type SerializableError = { message: string; stack?: string };

export const toSerializableError = (error: any): SerializableError => {
  const serializableError: SerializableError = {
    message:
      typeof error === 'string'
        ? error
        : error != null
          ? error.data?.data?.message ||
            error.data?.message ||
            error.message ||
            ''
          : '',
  };
  if (error?.stack) {
    serializableError.stack = error.stack;
  }
  return serializableError;
};
