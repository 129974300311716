import { DatamodelDownloads } from './components/downloads';
import { DatamodelFields } from './components/fields';
import { DatamodelFilters } from './components/filters';
import { LoadingBar } from './components/loading/bar';
import { PaginationBar } from './components/pagination/bar';

export function DatamodelDocumentation() {
  return (
    <>
      <DatamodelFilters />
      <DatamodelDownloads />
      <PaginationBar />
      <DatamodelFields />
      <PaginationBar footer />
      <LoadingBar />
    </>
  );
}
