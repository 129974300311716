import classnames from 'classnames';
import { ButtonHTMLAttributes, DetailedHTMLProps } from 'react';

import styles from './button.module.scss';

export const Button = ({
  children,
  className,
  link,
  onClick,
  primary,
  secondary,
  ...props
}: DetailedHTMLProps<
  ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
> & { primary?: boolean; secondary?: boolean; link?: boolean }) => {
  return (
    <button
      className={classnames(
        styles.button,
        primary && styles.buttonPrimary,
        secondary && styles.buttonSecondary,
        link && styles.buttonLink,
        className,
      )}
      onClick={(event) => {
        if (!props.disabled && onClick) {
          onClick(event);
        }
      }}
      {...props}
    >
      {children}
    </button>
  );
};
