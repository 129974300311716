import classnames from 'classnames';
import type { ReactNode } from 'react';

import styles from './filter.module.scss';

export function Filter({
  label,
  children,
  loading,
  labelVerticalAlign,
}: {
  label: string;
  children: ReactNode;
  loading?: boolean;
  labelVerticalAlign?: 'top' | 'middle';
}) {
  return (
    <div className={classnames(styles.filter, loading && styles.filterLoading)}>
      <div
        className={classnames(
          styles.filterLabel,
          labelVerticalAlign === 'middle' && styles.filterLabelMiddle,
        )}
      >
        {label}
      </div>
      <div className={styles.filterBody}>{children}</div>
    </div>
  );
}
