import classnames from 'classnames';
import type { Immutable } from 'immer';
import { useEffect, useRef, useState } from 'react';

import { ClickOutside } from '@alkem/react-ui-click-outside';

import { useDebounce } from 'src/utils/hooks/use-debounce';
import { DataNode, flatMapTree } from 'src/utils/tree';

import { Tree } from '../tree';

import styles from './tree-select.module.scss';

export type TreeSelectProps<
  TreeDataType extends DataNode | Immutable<DataNode>,
> = {
  clearable?: boolean;
  data: TreeDataType[] | Immutable<TreeDataType[]>;
  defaultSelected?: TreeDataType;
  flat?: boolean;
  inline?: boolean;
  isLoading?: boolean;
  onFilter: (query: string) => void;
  onSelect: (item?: TreeDataType) => void;
  selectedValue?: TreeDataType;
  testid?: string;
};

export function TreeSelect<
  TreeDataType extends DataNode | Immutable<DataNode>,
>({
  clearable = true,
  data,
  defaultSelected,
  flat = false,
  inline,
  isLoading,
  onFilter,
  onSelect,
  testid,
  ...props
}: TreeSelectProps<TreeDataType>) {
  const isControlled = 'selectedValue' in props;
  const [isTreeVisible, setTreeVisible] = useState(false);
  const [search, setSearch] = useState('');
  const [selectedState, setSelected] = useState<
    undefined | TreeDataType | Immutable<TreeDataType>
  >(defaultSelected);
  const [expanded, setExpanded] = useState<TreeDataType['key'][]>([]);
  const selectedValue = isControlled ? props.selectedValue : selectedState;

  const [, cancelDebounce] = useDebounce(search, 800, (value) => {
    onFilter(value);
  });

  const searchRef = useRef(search);
  searchRef.current = search;
  useEffect(() => {
    if (!flat && searchRef.current) {
      setExpanded(
        flatMapTree<
          TreeDataType | Immutable<TreeDataType>,
          number | string | bigint
        >(data, 'children', (item) => item.key),
      );
    }
  }, [data, flat]);

  const emptyState: string =
    isLoading && !data.length ? 'Loading...' : !data.length ? 'No results' : '';

  return (
    <ClickOutside
      className={classnames(
        styles.selectTree,
        inline && styles.selectTreeInline,
      )}
      onClickOutside={() => setTreeVisible(false)}
    >
      <div className={styles.inputBlock} data-testid={testid}>
        <input
          type="text"
          className={styles.input}
          data-testid="search-input"
          value={isTreeVisible ? search : selectedValue?.title || ''}
          onChange={(event) => setSearch(event.target.value)}
          onFocus={() => setTreeVisible(true)}
        />
        <div className={styles.icons}>
          {isTreeVisible && isLoading ? (
            <i className="mdi mdi-loading mdi-spin" />
          ) : null}
          {(clearable && selectedValue) || (isTreeVisible && search) ? (
            <button
              className={styles.closeButton}
              data-testid="clear-button"
              onClick={() => {
                cancelDebounce();
                if (search) {
                  setSearch('');
                  onFilter('');
                  if (!flat) {
                    setExpanded([]);
                  }
                }
                if (clearable && selectedValue) {
                  if (!isControlled) {
                    setSelected(undefined);
                  }
                  onSelect();
                }
              }}
            >
              <i className="mdi mdi-close" />
            </button>
          ) : null}
        </div>
      </div>
      {isTreeVisible ? (
        emptyState ? (
          <div className={classnames(styles.tree, styles.treeEmpty)}>
            {emptyState}
          </div>
        ) : (
          <Tree<TreeDataType>
            className={styles.tree}
            data={data}
            expandedIds={flat ? undefined : expanded}
            onExpand={flat ? undefined : (ids) => setExpanded(ids)}
            onSelect={(_, event) => {
              const [selectedItem] = event.selectedNodes;
              if (!isControlled) {
                setSelected(selectedItem);
              }
              setTreeVisible(false);
              onSelect(selectedItem);
            }}
          />
        )
      ) : null}
    </ClickOutside>
  );
}
