import { getDayEnd, getDayStart } from '@wojtekmaj/date-utils';
import { useEffect } from 'react';

import { Checkboxes } from 'src/components/checkbox';
import { DateRangePicker } from 'src/components/date/date-range-picker';
import { Select } from 'src/components/select/flat-select';
import { TreeSelect } from 'src/components/select/tree-select';
import { useDispatch, useSelector } from 'src/store';
import { useQueryParam } from 'src/utils/hooks/use-query-param';
import { mapRepeat } from 'src/utils/list';
import { randomInt } from 'src/utils/number';

import {
  fetchFiltersAction,
  fetchKindFiltersAction,
  updateCreatedDateRange,
  updateSearchQueryAction,
  updateSelectedFiltersAction,
  updateSelectedKind,
  updateSelectedLang,
} from '../../actions/filters';
import { ROOTS_CORE, ROOT_CONSUMER_UNIT } from '../../constants';

import styles from './index.module.scss';
import { LanguageFilters } from './languages';
import { Filter } from './layouts/filter';
import { SearchFilter } from './search';

export function DatamodelFilters() {
  const rootIdParam = useQueryParam('root');
  const dispatch = useDispatch();
  const filtersData = useSelector(
    (state) => state.datamodelFilters.filtersData,
  );
  const selectedFilters = useSelector(
    (state) => state.datamodelFilters.selectedFilters,
  );
  const fetching = useSelector((state) => state.datamodelFilters.fetching);

  useEffect(() => {
    if (rootIdParam) {
      const rootId = parseInt(rootIdParam, 10);
      dispatch(
        updateSelectedFiltersAction({
          root: { key: `${rootId}`, title: '', id: rootId },
        }),
      );
    }
  }, [dispatch, rootIdParam]);

  useEffect(() => {
    dispatch(fetchFiltersAction());
    dispatch(fetchKindFiltersAction());
  }, [dispatch]);

  return (
    <div className={styles.main}>
      <Filter label="Language" labelVerticalAlign="middle">
        <LanguageFilters
          onSelect={(selectedLanguage) => {
            dispatch(updateSelectedLang(selectedLanguage));
          }}
          selectedValue={selectedFilters.lang}
        />
      </Filter>
      <Filter label="Unit type" labelVerticalAlign="middle">
        <Select
          testid="root_list"
          clearable={false}
          data={filtersData.roots}
          isLoading={fetching.filters}
          onSelect={(root) => {
            dispatch(updateSelectedFiltersAction({ root }));
          }}
          onFilter={(query) => {
            dispatch(fetchKindFiltersAction(query));
          }}
          selectedValue={selectedFilters.root}
        />
      </Filter>
      {selectedFilters.root.id === ROOT_CONSUMER_UNIT && (
        <Filter label="SupplierXM product category" labelVerticalAlign="middle">
          <TreeSelect
            data={filtersData.kinds}
            isLoading={fetching.kinds}
            onSelect={(selectedKind) => {
              dispatch(updateSelectedKind(selectedKind));
            }}
            onFilter={(query) => {
              dispatch(fetchKindFiltersAction(query));
            }}
            selectedValue={selectedFilters.kind}
          />
        </Filter>
      )}
      {ROOTS_CORE.includes(selectedFilters.root.id) && (
        <Filter label="Packaging types" loading={fetching.filters}>
          <Checkboxes
            options={
              fetching.filters
                ? mapRepeat(randomInt(1, 4), (id) => ({
                    id,
                    label: '-'.repeat(randomInt(10, 20)),
                  }))
                : filtersData.packaging_types
            }
            selection={selectedFilters.packaging_type_ids}
            getId={(option) => option.id}
            getLabel={(option) => option.label}
            disabled={fetching.filters}
            onChange={(packagingTypeIds) => {
              dispatch(
                updateSelectedFiltersAction({
                  packaging_type_ids: packagingTypeIds,
                }),
              );
            }}
          />
        </Filter>
      )}
      <Filter label="Field types" loading={fetching.filters}>
        <Checkboxes
          options={
            fetching.filters
              ? mapRepeat(randomInt(1, 4), (id) => ({
                  ids: [id],
                  label: '-'.repeat(randomInt(10, 20)),
                }))
              : filtersData.visibilities
          }
          selection={selectedFilters.visibilities}
          getId={(option) => option.ids}
          getLabel={(option) => option.label}
          disabled={fetching.filters}
          onChange={(visibilityIds) => {
            dispatch(
              updateSelectedFiltersAction({
                visibilities: visibilityIds,
              }),
            );
          }}
        />
      </Filter>
      {filtersData.recipients.length > 0 && (
        <Filter label="Recipient" labelVerticalAlign="middle">
          <Select
            testid="recipient_list"
            clearable
            data={filtersData.recipients}
            isLoading={fetching.filters}
            onSelect={(recipient) => {
              dispatch(updateSelectedFiltersAction({ recipient }));
            }}
            onFilter={(query) => {
              dispatch(fetchKindFiltersAction(query));
            }}
            selectedValue={selectedFilters.recipient}
          />
        </Filter>
      )}

      <Filter label="Attribute creation date" labelVerticalAlign="middle">
        <DateRangePicker
          onChange={([from, to]) => {
            const createdAfter = from ? getDayStart(from).toJSON() : undefined;
            const createdBefore = to ? getDayEnd(to).toJSON() : undefined;
            dispatch(updateCreatedDateRange([createdAfter, createdBefore]));
          }}
        />
      </Filter>
      <Filter label="Search query" labelVerticalAlign="middle">
        <SearchFilter
          query={selectedFilters.search}
          onChange={(query) => {
            dispatch(updateSearchQueryAction(query));
          }}
        />
      </Filter>
    </div>
  );
}
