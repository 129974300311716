import { datadogRum } from '@datadog/browser-rum';

import { User } from '@alkem/sdk-dashboard';

import { getAppConfig } from './app-config';

const getDatadogConfig = () => getAppConfig().datadog || { enabled: false };

export function initDataDogRUM() {
  try {
    const config = getDatadogConfig();
    if (config.enabled) {
      const { enabled, ...rumConfig } = config;
      datadogRum.init({
        ...rumConfig,
        version: global.DATAMODEL_DOC_VERSION,
      });
    }
  } catch (err) {
    console.error(err);
  }
}

export function identifyDataDogSession(user: User) {
  try {
    if (getDatadogConfig().enabled) {
      datadogRum.setUser({
        id: String(user.id),
        oid: String(user.belongsTo[0].id),
      });
    }
  } catch (err) {
    console.error(err);
  }
}
