import { fork } from 'redux-saga/effects';

import { loginSaga } from './login';
import { logoutSaga } from './logout';
import { userSaga } from './user';

export function* authSaga() {
  yield fork(loginSaga);
  yield fork(logoutSaga);
  yield fork(userSaga);
}
