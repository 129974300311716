import { useEffect, useRef, useState } from 'react';

export function useDebounce<Value>(
  value: Value,
  delay: number,
  callback?: (value: Value) => void,
) {
  const [debouncedValue, setDebouncedValue] = useState<Value>(value);
  const cancelRef = useRef(() => {});

  useEffect(() => {
    let timer: NodeJS.Timeout | null = null;
    cancelRef.current = () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
    if (value !== debouncedValue) {
      timer = setTimeout(() => {
        (setDebouncedValue as (value: Value) => void)(value);
        if (timer && callback) {
          callback(value);
        }
      }, delay);
    }
    return () => {
      cancelRef.current();
      timer = null;
    };
  }, [value]); // eslint-disable-line react-hooks/exhaustive-deps

  return [debouncedValue, cancelRef.current] as [Value, Function];
}
