import classnames from 'classnames';

import { Button } from 'src/components/button';
import { Checkbox } from 'src/components/checkbox';
import logo from 'src/images/logo-dark.png';
import { useDispatch, useSelector } from 'src/store';

import {
  loggingInAction,
  updatePasswordAction,
  updateRememberMeAction,
  updateUsernameAction,
} from '../actions/login';

import { LoginError } from './error';
import styles from './login.module.scss';

export function LoginPage() {
  const dispatch = useDispatch();
  const { username, password, rememberMe, isLoggingIn, loginError } =
    useSelector((state) => state.login);

  return (
    <div className={styles.main}>
      <form
        className={styles.form}
        onSubmit={(event) => {
          event.preventDefault();
          if (!isLoggingIn) {
            dispatch(loggingInAction());
          }
        }}
      >
        <img src={logo} alt="Salsify" className={styles.logo} />
        <label className={styles.textLabel}>
          <span>Username</span>
          <input
            type="text"
            name="username"
            value={username}
            onChange={(event) => {
              dispatch(updateUsernameAction(event.target.value));
            }}
          />
        </label>
        <label className={styles.textLabel}>
          <span>Password</span>
          <input
            type="password"
            name="password"
            value={password}
            onChange={(event) => {
              dispatch(updatePasswordAction(event.target.value));
            }}
          />
        </label>
        <Checkbox
          checked={rememberMe}
          onChange={(isChecked) => {
            dispatch(updateRememberMeAction(isChecked));
          }}
        >
          Remember me
        </Checkbox>
        <Button
          type="submit"
          className={styles.submit}
          disabled={isLoggingIn}
          primary
        >
          <i
            className={classnames(
              'mdi',
              isLoggingIn ? 'mdi-loading mdi-spin' : 'mdi-login-variant',
            )}
          />
          Sign in
        </Button>
        {loginError ? <LoginError error={loginError} /> : null}
      </form>
    </div>
  );
}
