import type { Immutable } from 'immer';
import { useState } from 'react';

import { Select } from 'src/components/select/flat-select';

import { RecipientType } from '../../../types';

export function RecipientSelect({
  recipients,
  onSelect,
  selectedRecipient,
}: {
  recipients: Immutable<RecipientType[]>;
  onSelect: (recipient?: RecipientType) => void;
  selectedRecipient: RecipientType;
}) {
  const [options, setOptions] = useState(recipients);
  if (recipients.length === 1) {
    return <input disabled value={selectedRecipient.title} />;
  }
  return (
    <Select
      clearable={false}
      data={options}
      defaultSelected={recipients.length > 0 ? recipients[0] : undefined}
      onSelect={onSelect}
      onFilter={(query) => {
        if (query) {
          setOptions((state) =>
            state.filter((r) =>
              r.title.toLowerCase().includes(query.toLowerCase()),
            ),
          );
        } else {
          setOptions(recipients);
        }
      }}
      selectedValue={selectedRecipient || undefined}
      testid="recipient-select"
    />
  );
}
