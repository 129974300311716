import { createReducer } from '@reduxjs/toolkit';

import { ENGLISH_LANGUAGE } from 'src/constants/languages';

import {
  fetchFiltersAction,
  fetchKindFiltersAction,
  switchRootFiltersAction,
  updateCreatedDateRange,
  updateFiltersDataAction,
  updateKindFilters,
  updateSearchQueryAction,
  updateSelectedFiltersAction,
  updateSelectedKind,
  updateSelectedLang,
} from '../actions/filters';
import { ROOT_CONSUMER_UNIT } from '../constants';
import type { DatamodelFiltersStateImmutable, RootType } from '../types';

export const datamodelFiltersReducerKey = 'datamodelFilters';

export const datamodelFiltersInitialState: DatamodelFiltersStateImmutable = {
  filtersData: {
    packaging_types: [],
    roots: [],
    visibilities: [],
    recipients: [],
    kinds: [],
  },
  selectedFilters: {
    search: '',
    kind: undefined,
    packaging_type_ids: [],
    root: { key: `${ROOT_CONSUMER_UNIT}`, title: '', id: ROOT_CONSUMER_UNIT },
    visibilities: [],
    recipient: undefined,
    created_after: undefined,
    created_before: undefined,
    lang: ENGLISH_LANGUAGE,
    offset: 0,
    limit: 100,
  },
  fetching: {
    filters: false,
    kinds: false,
  },
};

export const datamodelFiltersReducer =
  createReducer<DatamodelFiltersStateImmutable>(
    datamodelFiltersInitialState,
    (builder) => {
      builder
        .addCase(fetchFiltersAction, (state) => {
          state.fetching.filters = true;
        })
        .addCase(fetchKindFiltersAction, (state) => {
          state.fetching.kinds = true;
        })
        .addCase(updateFiltersDataAction, (state, action) => {
          state.fetching.filters = false;
          state.filtersData = {
            ...state.filtersData,
            packaging_types: action.payload.packaging_types,
            visibilities: action.payload.visibilities,
          };
          state.filtersData.roots = action.payload.roots.map((e) => ({
            key: `${e.id}-${e.label}`,
            title: e.label,
            id: e.id,
          }));
          state.selectedFilters.root = state.filtersData.roots.find(
            (r) => r.id === state.selectedFilters.root.id,
          ) as RootType;
          state.filtersData.recipients = (action.payload.recipients || [])
            .sort((a, b) => (a.name > b.name ? 1 : -1))
            .map((e) => ({
              key: e.id,
              title: e.name,
            }));
        })
        .addCase(updateKindFilters, (state, action) => {
          state.filtersData.kinds = action.payload;
          state.fetching.kinds = false;
        })
        .addCase(updateSelectedFiltersAction, (state, action) => {
          state.selectedFilters = {
            ...state.selectedFilters,
            ...action.payload,
          };
          if (!('offset' in action.payload)) {
            state.selectedFilters.offset = 0;
          }
        })
        .addCase(updateSelectedKind, (state, action) => {
          state.selectedFilters.kind = action.payload
            ? { key: action.payload.key, title: action.payload.title }
            : undefined;
          state.selectedFilters.offset = 0;
        })
        .addCase(updateSelectedLang, (state, action) => {
          state.selectedFilters.lang = action.payload;
        })
        .addCase(updateCreatedDateRange, (state, action) => {
          const [after, before] = action.payload;
          state.selectedFilters.created_after = after;
          state.selectedFilters.created_before = before;
          state.selectedFilters.offset = 0;
        })
        .addCase(updateSearchQueryAction, (state, action) => {
          state.selectedFilters.search = action.payload;
          state.selectedFilters.offset = 0;
        })
        .addCase(switchRootFiltersAction, (state, action) => {
          state.selectedFilters = {
            ...state.selectedFilters,
            search: '',
            root: state.filtersData.roots.filter(
              (e) => e.id === action.payload,
            )[0],
          };
        });
    },
  );
