import { Referential, ResponseWithBody } from '@alkem/sdk-dashboard';

import { toISODateString } from 'src/utils/date';
import { filterBody } from 'src/utils/request';

import type {
  FieldsResponseType,
  FiltersResponseType,
  ReferentialLink,
  SelectedFiltersType,
} from '../types';

import { coreClient, fieldClient } from './clients';

export async function fetchFilters() {
  const response = await fieldClient().get<
    FiltersResponseType,
    ResponseWithBody<FiltersResponseType>
  >('/field/documentation/v1/filters');
  return response.data;
}

export type KindResponseType = {
  id: number;
  label: string;
  children?: KindResponseType[];
};

export async function fetchKinds({ search }: { search?: string }) {
  let route = '/core/v3/referentials/kinds?all_entities=true&as_tree=true';
  if (search) {
    route += `&search=${search}`;
  }
  const response = await coreClient().get<KindResponseType[]>(route);
  return response.data.data;
}

interface FieldsRequestBody
  extends Omit<
    SelectedFiltersType,
    'created_after' | 'created_before' | 'kind' | 'lang' | 'root'
  > {
  created_after?: string | null;
  created_before?: string | null;
  kind_id?: number;
  lang?: string;
  root?: number;
  recipient_id?: number;
}

function createFieldsRequestBody(
  filters: SelectedFiltersType,
): FieldsRequestBody {
  return {
    ...filters,
    created_after: toISODateString(filters.created_after),
    created_before: toISODateString(filters.created_before),
    kind_id: filters.kind?.key,
    lang: filters.lang.key,
    root: filters.root.id,
    recipient_id: filters.recipient?.key,
  };
}

export async function fetchFields(filters: SelectedFiltersType) {
  const body = createFieldsRequestBody(filters);
  const response = await fieldClient().post<
    FieldsResponseType,
    ResponseWithBody<FieldsResponseType>
  >('/field/documentation/v1/search', filterBody(body));
  return response.data;
}

export async function fetchReferential(
  link: ReferentialLink,
  lang: string,
  orgId: number,
) {
  const params = `lang=${lang}&organization_id=${orgId}&retailer_id=${orgId}`;
  const response = await coreClient().get<Referential[]>(
    link.path
      ? `${link.path}?referential=${link.slug}&${params}`
      : `/core/v3/referentials/${link.slug}?${params}`,
  );
  return response.data.data;
}

export async function downloadAllReferentials(filters: SelectedFiltersType) {
  const body = createFieldsRequestBody(filters);
  return fieldClient().getBlob<Blob, ResponseWithBody<Blob>>(
    '/field/documentation/v1/downloads/referentials',
    '',
    filterBody(body),
    true,
  );
}

export async function downloadAllJsonSamples(filters: SelectedFiltersType) {
  const body = createFieldsRequestBody(filters);
  return fieldClient().getBlob<Blob, ResponseWithBody<Blob>>(
    '/field/documentation/v1/downloads/json-samples',
    '',
    filterBody(body),
    true,
  );
}

export async function downloadAllXmlSamples(filters: SelectedFiltersType) {
  const body = createFieldsRequestBody(filters);
  return fieldClient().getBlob<Blob, ResponseWithBody<Blob>>(
    '/field/documentation/v1/downloads/xml-samples',
    '',
    filterBody(body),
    true,
  );
}
