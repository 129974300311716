import { call, put } from 'redux-saga/effects';

import { toSerializableError } from 'src/utils/errors';
import { takeLatestSafe } from 'src/utils/sagas';
import { toTreeOptions } from 'src/utils/tree';

import {
  FETCH_FILTERS,
  FETCH_KIND_FILTERS,
  fetchFiltersErrorAction,
  fetchKindFiltersAction,
  fetchKindFiltersErrorAction,
  updateFiltersDataAction,
  updateKindFilters,
} from '../actions/filters';
import { KindResponseType, fetchFilters, fetchKinds } from '../api';
import type { FiltersResponseType, KindType } from '../types';

export function* filtersSaga() {
  yield takeLatestSafe(FETCH_FILTERS, fetchFiltersSaga);
  yield takeLatestSafe(FETCH_KIND_FILTERS, fetchKindsSaga);
}

function* fetchFiltersSaga() {
  try {
    const response: FiltersResponseType = yield call(fetchFilters);
    yield put(updateFiltersDataAction(response));
  } catch (error) {
    console.error(error);
    yield put(fetchFiltersErrorAction(toSerializableError(error)));
  }
}

function* fetchKindsSaga(action: ReturnType<typeof fetchKindFiltersAction>) {
  try {
    const kinds: KindResponseType[] = yield call(fetchKinds, {
      search: action.payload,
    });
    const kindOptions = toTreeOptions<KindResponseType, KindType>(kinds);
    yield put(updateKindFilters(kindOptions));
  } catch (error) {
    console.error(error);
    yield put(fetchKindFiltersErrorAction(toSerializableError(error)));
  }
}
