import classNames from 'classnames';

import { Button } from 'src/components/button';
import { useDispatch, useSelector } from 'src/store';

import {
  downloadAllJsonSamplesAction,
  downloadAllReferentialsAction,
  downloadAllXmlSamplesAction,
} from '../../actions/downloads';

import styles from './index.module.scss';

export function DatamodelDownloads() {
  const dispatch = useDispatch();

  const isDownloadingReferentials = useSelector(
    (state) => state.datamodelDownloads.isDownloadingReferentials,
  );

  const isDownloadingJsonSamples = useSelector(
    (state) => state.datamodelDownloads.isDownloadingJsonSamples,
  );

  const isDownloadingXmlSamples = useSelector(
    (state) => state.datamodelDownloads.isDownloadingXmlSamples,
  );

  return (
    <div className={styles.main}>
      <Button
        className={styles.button}
        data-testid="download-all-referentialsbutton"
        onClick={() => {
          dispatch(downloadAllReferentialsAction());
        }}
        disabled={isDownloadingReferentials}
        primary
      >
        <i
          className={classNames(
            'mdi',
            isDownloadingReferentials ? 'mdi-loading mdi-spin' : 'mdi-download',
          )}
        />
        Code list values
      </Button>
      <Button
        className={styles.button}
        data-testid="download-all-json-samples-button"
        onClick={() => {
          dispatch(downloadAllJsonSamplesAction());
        }}
        disabled={isDownloadingJsonSamples}
        primary
      >
        <i
          className={classNames(
            'mdi',
            isDownloadingJsonSamples ? 'mdi-loading mdi-spin' : 'mdi-download',
          )}
        />
        JSON samples
      </Button>
      <Button
        className={styles.button}
        data-testid="download-all-xml-samples-button"
        onClick={() => {
          dispatch(downloadAllXmlSamplesAction());
        }}
        disabled={isDownloadingXmlSamples}
        primary
      >
        <i
          className={classNames(
            'mdi',
            isDownloadingXmlSamples ? 'mdi-loading mdi-spin' : 'mdi-download',
          )}
        />
        XML samples
      </Button>
    </div>
  );
}
