import { useMemo, useState } from 'react';

import { Select } from 'src/components/select/flat-select';
import { ENGLISH_LANGUAGE, FRENCH_LANGUAGE } from 'src/constants/languages';

import { LangType } from '../../types';

export function LanguageFilters({
  onSelect,
  selectedValue,
}: {
  onSelect: (lang: LangType) => void;
  selectedValue: LangType;
}) {
  const [allLanguages, defaultLang] = useMemo(() => {
    const en = ENGLISH_LANGUAGE;
    const all = [en, FRENCH_LANGUAGE].sort((a, b) =>
      a.title.localeCompare(b.title),
    );
    return [all, en];
  }, []);
  const [languages, setLanguages] = useState(allLanguages);
  return (
    <Select
      clearable={false}
      data={languages}
      defaultSelected={defaultLang}
      onSelect={(selectedLanguage) => {
        if (selectedLanguage) {
          onSelect(selectedLanguage);
        }
      }}
      onFilter={(query) => {
        if (query) {
          setLanguages((state) =>
            state.filter((lang) =>
              lang.title.toLowerCase().includes(query.toLowerCase()),
            ),
          );
        } else {
          setLanguages(allLanguages);
        }
      }}
      selectedValue={selectedValue}
      testid="language-select"
    />
  );
}
