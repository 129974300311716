import { createAction } from '@reduxjs/toolkit';

import { SerializableError } from 'src/utils/errors';

import type { FieldsResponseType } from '../types';

export const FETCH_FIELDS = 'datamodel/fetch-fields';
export const FETCHING_FIELDS = 'datamodel/fetching-fields';
export const FETCH_FIELDS_ERROR = 'datamodel/fetch-fields-error';
export const UPDATE_FIELDS = 'datamodel/update-fields';

export const fetchFieldsAction = createAction(FETCH_FIELDS);
export const fetchingFieldsAction = createAction<boolean>(FETCHING_FIELDS);
export const fetchFieldsErrorAction =
  createAction<SerializableError>(FETCH_FIELDS_ERROR);
export const updateFieldsDataAction =
  createAction<FieldsResponseType>(UPDATE_FIELDS);
