import classNames from 'classnames';

import styles from './input.module.scss';

export function TextInput({
  block,
  className,
  onChange,
  testid,
  value,
}: {
  block?: boolean;
  className?: string;
  onChange: (value: string) => void;
  testid?: string;
  value: string;
}) {
  return (
    <div
      className={classNames(
        styles.wrapper,
        block ? styles.block : styles.inline,
        className,
      )}
      data-testid={testid}
    >
      <input
        className={styles.textInput}
        data-testid="text-input"
        onChange={(event) => onChange(event.target.value)}
        type="text"
        value={value}
      />
      {value ? (
        <button
          className={styles.clearButton}
          data-testid="clear-button"
          onClick={() => {
            onChange('');
          }}
        >
          <i className="mdi mdi-close" />
        </button>
      ) : null}
    </div>
  );
}
