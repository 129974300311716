import type { ReactNode } from 'react';

import { switchRootFiltersAction } from 'src/modules/datamodel/actions/filters';
import { useDispatch, useSelector } from 'src/store';

import styles from './attributes.module.scss';

const DatamodelFieldAttribute = ({
  children,
  label,
}: {
  children: ReactNode;
  label: ReactNode;
}) => (
  <li className={styles.attributeItem}>
    {label ? <div className={styles.attributeLabel}>{label}</div> : null}
    <div>{children}</div>
  </li>
);

export type DatamodeFieldAttributeType = {
  id: string;
  label: ReactNode;
  children: ReactNode;
  hideIfEmpty?: boolean;
};

export const DatamodeFieldAttributes = ({
  children: attributes,
}: {
  children: DatamodeFieldAttributeType[];
}) => (
  <ul className={styles.attributeList}>
    {attributes.map(({ id, label, children, hideIfEmpty }) =>
      hideIfEmpty && !children ? null : (
        <DatamodelFieldAttribute key={id} label={label}>
          {children}
        </DatamodelFieldAttribute>
      ),
    )}
  </ul>
);

export function DatamodeFieldRootFieldsSwitcher({
  rootID,
}: {
  rootID: number;
}) {
  const dispatch = useDispatch();
  const rootName = useSelector(
    (state) =>
      state.datamodelFilters.filtersData.roots.filter((e) => e.id === rootID)[0]
        .title,
  );
  return (
    <ul className={styles.attributeList}>
      <li className={styles.attributeItem}>
        <div className={styles.attributeLabel}>Child attributes:</div>
        <div>
          <button
            className={styles.switchRootButton}
            onClick={() => {
              window.scroll(0, 0);
              dispatch(switchRootFiltersAction(rootID));
            }}
          >
            {rootName}
          </button>
        </div>
      </li>
    </ul>
  );
}
