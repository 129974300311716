import type CancelablePromise from 'cancelable-promise';

import { AuthApi, ResponseWithBody } from '@alkem/sdk-dashboard';

const client = () => new AuthApi();

const cast = <T = any>(p: any): CancelablePromise<T> => p;

export const fetchUserToken = async (username: string, password: string) => {
  const { data } = await cast<
    ResponseWithBody<{
      access_token: string;
      refresh_token: string;
    }>
  >(client().UserLogin(username, password));
  return {
    accessToken: data.access_token,
    refreshToken: data.refresh_token,
  };
};

export const fetchLogout = () => cast<void>(client().UserLogout());

export const fetchUser = () => client().UserMe({}, false);
