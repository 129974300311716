import { createAction } from '@reduxjs/toolkit';
import type { Immutable } from 'immer';

import type { SerializableError } from 'src/utils/errors';

import type {
  DatamodelFiltersState,
  FiltersResponseType,
  KindType,
  LangType,
  ReferentialType,
  ViewedReferential,
} from '../types';

export const FETCH_FILTERS = 'datamodel/fetch-filters';
export const FETCH_KIND_FILTERS = 'datamodel/fetch-kind-filters';
export const FETCH_FILTERS_ERROR = 'datamodel/fetch-filters-error';
export const FETCH_KIND_FILTERS_ERROR = 'datamodel/fetch-kind-filters-error';
export const UPDATE_FILTERS = 'datamodel/update-filters';
export const UPDATE_FILTER = 'datamodel/update-filter';
export const SWITCH_ROOT_FILTER = 'datamodel/switch-root-filter';
export const UPDATE_KIND_FILTERS = 'datamodel/update-kind-filters';
export const UPDATE_SELECTED_KIND = 'datamodel/update-selected-kind';
export const UPDATE_SELECTED_LANG = 'datamodel/update-selected-lang';
export const UPDATE_CREATED_DATE_RANGE = 'datamodel/update-created-date-range';
export const UPDATE_SEARCH_QUERY = 'datamodel/update-search-query';
export const SELECT_REFERENTIAL = 'datamodel/select-referential';
export const FETCH_REFERENTIAL = 'datamodel/fetch-referential';
export const UPDATE_REFERENTIAL = 'datamodel/update-referential';
export const RESET_REFERENTIAL = 'datamodel/reset-referential';

export const fetchFiltersAction = createAction(FETCH_FILTERS);
export const fetchKindFiltersAction = createAction<string | undefined>(
  FETCH_KIND_FILTERS,
);
export const updateFiltersDataAction =
  createAction<FiltersResponseType>(UPDATE_FILTERS);
export const updateSelectedFiltersAction =
  createAction<Partial<DatamodelFiltersState['selectedFilters']>>(
    UPDATE_FILTER,
  );
export const fetchFiltersErrorAction =
  createAction<SerializableError>(FETCH_FILTERS_ERROR);
export const fetchKindFiltersErrorAction = createAction<SerializableError>(
  FETCH_KIND_FILTERS_ERROR,
);
export const updateKindFilters = createAction<KindType[]>(UPDATE_KIND_FILTERS);
export const updateSelectedKind = createAction<
  KindType | Immutable<KindType> | undefined
>(UPDATE_SELECTED_KIND);
export const updateSelectedLang = createAction<LangType>(UPDATE_SELECTED_LANG);
export const updateCreatedDateRange = createAction<
  [undefined | string, undefined | string]
>(UPDATE_CREATED_DATE_RANGE);
export const updateSearchQueryAction =
  createAction<string>(UPDATE_SEARCH_QUERY);
export const selectReferentialAction =
  createAction<ViewedReferential>(SELECT_REFERENTIAL);
export const fetchReferentialAction = createAction<number | null>(
  FETCH_REFERENTIAL,
);
export const updateReferentialAction =
  createAction<ReferentialType[]>(UPDATE_REFERENTIAL);
export const resetReferentialAction = createAction(RESET_REFERENTIAL);
export const switchRootFiltersAction = createAction<number>(SWITCH_ROOT_FILTER);
