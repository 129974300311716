import classNames from 'classnames';
import type { Immutable } from 'immer';
import { ReactNode, useMemo, useState } from 'react';

import { selectReferentialAction } from 'src/modules/datamodel/actions/filters';
import type {
  FieldType,
  PackagingTypeDictType,
  RecipientType,
  ReferentialLink,
  SubFieldType,
} from 'src/modules/datamodel/types';
import { useDispatch } from 'src/store';
import { toLocaleDateString } from 'src/utils/date';
import { HTMLDiv } from 'src/utils/sanitizer';

import {
  DatamodeFieldAttributeType,
  DatamodeFieldAttributes,
  DatamodeFieldRootFieldsSwitcher,
} from './attributes';
import styles from './field.module.scss';
import { DatamodelFieldName } from './name';
import { DatamodelFieldSamples } from './samples';

const createFieldAttribute = (
  id: string,
  label: ReactNode,
  children: ReactNode,
  hideIfEmpty?: boolean,
): DatamodeFieldAttributeType => ({ id, label, children, hideIfEmpty });

function ReferentialButton({
  link,
  specificRecipients,
}: {
  link: ReferentialLink;
  specificRecipients: RecipientType[];
}) {
  const dispatch = useDispatch();
  return (
    <button
      className={styles.referentialButton}
      onClick={(event) => {
        event.preventDefault();
        dispatch(selectReferentialAction({ ...link, specificRecipients }));
      }}
    >
      {link.slug}
    </button>
  );
}

function getDataTypeDescription(
  data: Immutable<FieldType> | Immutable<SubFieldType>,
  specificRecipients: RecipientType[],
): ReactNode {
  if (
    data.referential &&
    ['ENTITY', 'ENUM', 'URI_ENTITY'].includes(data.type)
  ) {
    return (
      <>
        <span>one of&nbsp;</span>
        <ReferentialButton
          link={data.referential}
          specificRecipients={specificRecipients}
        />
      </>
    );
  } else if (data.declinable_by) {
    return (
      <>
        <span>{`${data.type} (declinable by `}</span>
        <ReferentialButton
          link={data.declinable_by}
          specificRecipients={specificRecipients}
        />
        <span>)</span>
      </>
    );
  }
  return data.type;
}

export function DatamodelField({
  data,
  packagingTypes,
  isRoot = true,
  parentPath = null,
  specificRecipients = null,
}: {
  data: Immutable<FieldType> | Immutable<SubFieldType>;
  packagingTypes: PackagingTypeDictType;
  isRoot?: boolean;
  parentPath?: string | null;
  specificRecipients?: RecipientType[] | null;
}) {
  const dataSpecificFor = 'specific_for' in data ? data.specific_for : null;
  const fieldSpecificRecipients = useMemo(
    () =>
      specificRecipients ||
      (dataSpecificFor
        ? Object.entries(dataSpecificFor).map(([k, v]) => ({
            key: parseInt(k, 10),
            title: v,
          }))
        : []),
    [dataSpecificFor, specificRecipients],
  );
  return (
    <div className={styles.field}>
      <div className={styles.attributesBlock}>
        <DatamodeFieldAttributes>
          {[
            createFieldAttribute(
              'name',
              '',
              <DatamodelFieldName name={data.name} />,
            ),
          ]}
        </DatamodeFieldAttributes>
        <DatamodeFieldAttributes>
          {[
            createFieldAttribute('label', 'Label:', data.label, true),

            'created_at' in data
              ? createFieldAttribute(
                  'created_at',
                  'Created at:',
                  toLocaleDateString(data.created_at),
                )
              : null,

            createFieldAttribute(
              'type',
              'Type:',
              getDataTypeDescription(data, fieldSpecificRecipients),
            ),

            createFieldAttribute(
              'repeatable',
              'Repeatable:',
              data.repeatable ? 'yes' : 'no',
            ),

            'packaging_type_ids' in data && data.packaging_type_ids?.length
              ? createFieldAttribute(
                  'packaging_type',
                  'Applies to',
                  data.packaging_type_ids
                    .map((id) => packagingTypes[id])
                    .join(', '),
                )
              : null,

            'overridable_for' in data &&
            data.overridable_for &&
            Object.keys(data.overridable_for).length > 0
              ? createFieldAttribute(
                  'overridable_for',
                  'Can be customised for:',
                  Object.values(data.overridable_for).join(', '),
                )
              : null,

            'specific_for' in data &&
            data.specific_for &&
            Object.keys(data.specific_for).length > 0
              ? createFieldAttribute(
                  'specific_for',
                  'Specific for:',
                  Object.values(data.specific_for).join(', '),
                )
              : null,

            'exclusive_for' in data &&
            data.exclusive_for &&
            Object.keys(data.exclusive_for).length > 0
              ? createFieldAttribute(
                  'exclusive_for',
                  'Exclusive for:',
                  Object.values(data.exclusive_for).join(', '),
                )
              : null,
          ].filter((f): f is DatamodeFieldAttributeType => f != null)}
        </DatamodeFieldAttributes>

        {'children_root' in data && data.children_root ? (
          <DatamodeFieldRootFieldsSwitcher rootID={data.children_root} />
        ) : null}
      </div>
      <div className={styles.descriptionBlock}>
        {data.description ? (
          <HTMLDiv className={styles.description}>{data.description}</HTMLDiv>
        ) : null}
        {'referential_links' in data && data.referential_links?.length ? (
          <>
            <div className={styles.referentialText}>
              See referential values:
            </div>
            <ul className={styles.referentialList}>
              {data.referential_links.map((link) => (
                <li key={link.slug}>
                  <ReferentialButton
                    link={link}
                    specificRecipients={fieldSpecificRecipients}
                  />
                </li>
              ))}
            </ul>
          </>
        ) : null}
      </div>
      {'samples' in data && data.samples ? (
        <div className={styles.samplesBlock}>
          <DatamodelFieldSamples samples={data.samples} />
        </div>
      ) : null}
      {data.children?.length ? (
        <>
          <div className={styles.subFieldText}>Sub Fields:</div>
          {data.children.map((subField) => (
            <DatamodelFieldChild
              key={subField.name}
              subField={subField}
              packagingTypes={packagingTypes}
              isRoot={isRoot}
              parentPath={getPath(parentPath, data.name)}
              specificRecipients={fieldSpecificRecipients}
            />
          ))}
        </>
      ) : null}
    </div>
  );
}

function DatamodelFieldChild({
  subField,
  packagingTypes,
  isRoot,
  parentPath,
  specificRecipients,
}: {
  subField: Immutable<SubFieldType>;
  packagingTypes: PackagingTypeDictType;
  isRoot?: boolean;
  parentPath: string;
  specificRecipients: RecipientType[];
}) {
  const [showDetails, setDetails] = useState(false);
  return (
    <div
      key={subField.name}
      className={classNames(styles.subField, isRoot && styles.subFieldRoot)}
      data-testid="subfield"
    >
      <div
        className={classNames(
          styles.subFieldBody,
          isRoot && styles.subFieldBodyRoot,
        )}
      >
        <button
          className={styles.subFieldHeader}
          onClick={() => setDetails((state) => !state)}
        >
          <i
            className={classNames(
              'mdi',
              showDetails ? 'mdi-chevron-down' : 'mdi-chevron-right',
            )}
          />
          {getPath(parentPath, subField.name)}
        </button>
        {showDetails ? (
          <DatamodelField
            data={subField}
            packagingTypes={packagingTypes}
            isRoot={false}
            parentPath={parentPath}
            specificRecipients={specificRecipients}
          />
        ) : null}
      </div>
    </div>
  );
}

function getPath(parentPath: string | null, name: string): string {
  return parentPath ? `${parentPath} / ${name}` : name;
}
