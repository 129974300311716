import { call, put, select } from 'redux-saga/effects';

import { RootState } from 'src/store/reducers';
import { toSerializableError } from 'src/utils/errors';
import { takeFirstSafe } from 'src/utils/sagas';
import { saveAs } from 'src/utils/save-as';

import {
  DOWNLOAD_ALL_JSON_SAMPLES,
  DOWNLOAD_ALL_REFERENTIALS,
  DOWNLOAD_ALL_XML_SAMPLES,
  downloadAllJsonSamplesErrorAction,
  downloadAllReferentialsErrorAction,
  downloadAllXmlSamplesErrorAction,
  downloadingAllJsonSamplesAction,
  downloadingAllReferentialsAction,
  downloadingAllXmlSamplesAction,
} from '../actions/downloads';
import {
  downloadAllJsonSamples,
  downloadAllReferentials,
  downloadAllXmlSamples,
} from '../api';
import type { SelectedFiltersType } from '../types';

export function* downloadsSaga() {
  yield takeFirstSafe(DOWNLOAD_ALL_REFERENTIALS, downloadAllReferentialsSaga);
  yield takeFirstSafe(DOWNLOAD_ALL_JSON_SAMPLES, downloadAllJsonSamplesSaga);
  yield takeFirstSafe(DOWNLOAD_ALL_XML_SAMPLES, downloadAllXmlSamplesSaga);
}

function* downloadAllReferentialsSaga() {
  try {
    yield put(downloadingAllReferentialsAction(true));
    const filters: SelectedFiltersType = yield select(
      (state: RootState) => state.datamodelFilters.selectedFilters,
    );
    const response = yield call(downloadAllReferentials, filters);
    saveAs(response.data, 'code_list_values.csv');
  } catch (error) {
    console.error(error);
    yield put(downloadAllReferentialsErrorAction(toSerializableError(error)));
  } finally {
    yield put(downloadingAllReferentialsAction(false));
  }
}

function* downloadAllJsonSamplesSaga() {
  try {
    yield put(downloadingAllJsonSamplesAction(true));
    const filters: SelectedFiltersType = yield select(
      (state: RootState) => state.datamodelFilters.selectedFilters,
    );
    const response = yield call(downloadAllJsonSamples, filters);
    saveAs(response.data, 'json_samples.json');
  } catch (error) {
    console.error(error);
    yield put(downloadAllJsonSamplesErrorAction(toSerializableError(error)));
  } finally {
    yield put(downloadingAllJsonSamplesAction(false));
  }
}

function* downloadAllXmlSamplesSaga() {
  try {
    yield put(downloadingAllXmlSamplesAction(true));
    const filters: SelectedFiltersType = yield select(
      (state: RootState) => state.datamodelFilters.selectedFilters,
    );
    const response = yield call(downloadAllXmlSamples, filters);
    saveAs(response.data, 'xml_samples.xml');
  } catch (error) {
    console.error(error);
    yield put(downloadAllXmlSamplesErrorAction(toSerializableError(error)));
  } finally {
    yield put(downloadingAllXmlSamplesAction(false));
  }
}
