import { useSelector } from 'src/store';

import styles from './bar.module.scss';

export function LoadingBar() {
  const isFetchingFields = useSelector(
    (state) => state.datamodelFields.isFetching,
  );
  return isFetchingFields ? (
    <div className={styles.loadingBar} data-testid="loading-bar" />
  ) : null;
}
