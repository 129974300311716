import { reload } from 'src/utils/location';

import styles from './fallback.module.scss';

export const FallbackComponent = ({ error }) => (
  <div className={styles.main}>
    <div>
      <h3 className={styles.title}>
        <i className="mdi mdi-alert" />
        An error occured.&nbsp;
        <a
          onClick={(event) => {
            event.preventDefault();
            reload();
          }}
        >
          Retry.
        </a>
      </h3>
    </div>
    <pre>{error.stack}</pre>
  </div>
);
