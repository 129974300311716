import { add, remove } from 'src/utils/list';

import { Checkbox } from './checkbox';

export function Checkboxes<OptionType, SelectedType>({
  disabled,
  getId,
  getLabel,
  options,
  onChange,
  selection,
}: {
  disabled?: boolean;
  getId: (
    item: OptionType,
  ) => SelectedType | SelectedType[] | ReadonlyArray<SelectedType>;
  getLabel: (item: OptionType) => string;
  options: OptionType[] | ReadonlyArray<OptionType>;
  onChange: (selection: SelectedType[]) => void;
  selection: SelectedType[] | ReadonlyArray<SelectedType>;
}) {
  return (
    <>
      {options.map((option: OptionType) => {
        const id = getId(option);
        const ids = Array.isArray(id) ? id : [id as SelectedType];
        const label: string = getLabel(option);
        return (
          <Checkbox
            key={ids.join()}
            checked={ids.every((i) => selection.includes(i))}
            disabled={disabled}
            onChange={(isChecked) => {
              onChange(
                isChecked ? add(selection, ...ids) : remove(selection, ...ids),
              );
            }}
          >
            {label}
          </Checkbox>
        );
      })}
    </>
  );
}
