import { Button } from 'src/components/button';
import { useDispatch } from 'src/store';

import { fetchLogoutAction } from '../actions/logout';

import styles from './logout.module.scss';

export const LogoutAction = () => {
  const dispatch = useDispatch();
  return (
    <div className={styles.logout}>
      <Button
        data-testid="logout-button"
        onClick={() => {
          dispatch(fetchLogoutAction());
        }}
        link
      >
        <i className="mdi mdi-logout" /> Logout
      </Button>
    </div>
  );
};
