import classnames from 'classnames';
import { useMemo, useState } from 'react';
import ReactDatePicker from 'react-date-picker';

import styles from './date-picker.module.scss';

const CalendarIcon = () => (
  <i
    className={classnames('mdi mdi-calendar-month', styles.calendarIcon)}
    data-testid="date-picker-icon"
  />
);

const ClearIcon = ({ disabled }: { disabled: boolean }) => (
  <i
    className={classnames(
      'mdi mdi-close',
      styles.clearIcon,
      disabled && styles.clearIconDisabled,
    )}
    data-testid="date-picker-clear"
  />
);

export function DatePicker({
  maxDate,
  minDate,
  name = 'date-picker-input',
  onChange,
  ...props
}: {
  maxDate?: Date;
  minDate?: Date;
  name?: string;
  onChange?: (value: Date) => void;
  value?: Date;
}) {
  const [date, setDate] = useState<undefined | Date>(props.value);
  const isControlled = 'value' in props;
  const selectedDate = isControlled ? props.value : date;

  const dateFormat = useMemo(() => {
    const dateFmt = new Intl.DateTimeFormat().format(
      new Date('2000-05-15T12:00:00'),
    );
    if (dateFmt.startsWith('15')) {
      return 'dd-MM-y';
    } else if (dateFmt.startsWith('2000')) {
      return 'y-MM-dd';
    }
    return 'MM-dd-y';
  }, []);

  return (
    <ReactDatePicker
      autoFocus={false}
      calendarAriaLabel="Toggle calendar"
      calendarIcon={<CalendarIcon />}
      className={classnames(
        styles.datePicker,
        !selectedDate && styles.datePickerNoClear,
      )}
      clearAriaLabel="Clear value"
      clearIcon={<ClearIcon disabled={!selectedDate} />}
      closeCalendar
      dayAriaLabel="Day"
      disableCalendar={false}
      disabled={false}
      format={dateFormat}
      maxDate={maxDate}
      minDate={minDate}
      monthAriaLabel="Month"
      name={name}
      nativeInputAriaLabel="Date"
      onChange={(newValue) => {
        if (!isControlled) {
          setDate(newValue as Date);
        }
        if (onChange) {
          onChange(newValue as Date);
        }
      }}
      openCalendarOnFocus
      value={selectedDate}
      yearAriaLabel="Year"
    />
  );
}
