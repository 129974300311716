import { useMemo } from 'react';

import { Button } from 'src/components/button';
import { useDispatch, useSelector } from 'src/store';

import { updateSelectedFiltersAction } from '../../actions/filters';

import styles from './bar.module.scss';

export function PaginationBar(props: { footer?: boolean }) {
  const isFetching = useSelector(
    (state) =>
      state.datamodelFields.isFetching ||
      state.datamodelFilters.fetching.filters,
  );
  const fieldCount = useSelector((state) => state.datamodelFields.total);
  const offset = useSelector(
    (state) => state.datamodelFilters.selectedFilters.offset,
  );
  const limit = useSelector(
    (state) => state.datamodelFilters.selectedFilters.limit,
  );
  const totalPages = Math.ceil(fieldCount / limit);
  const currentPage = offset / limit + 1;
  const rangeLeft = fieldCount ? offset + 1 : 0;
  const rangeRight = fieldCount < offset + limit ? fieldCount : offset + limit;

  if (props.footer && fieldCount === 0) {
    return null;
  }

  return (
    <div className={styles.paginationFlexContainer}>
      <div data-testid="info">
        {isFetching === false &&
          fieldCount > 0 &&
          `Showing ${rangeLeft} to ${rangeRight} of ${fieldCount} fields`}
        {isFetching === false && fieldCount === 0 && (
          <span>
            No fields matching your search...
            <i className={`${styles.miniPad} mdi mdi-heart-broken`} />
          </span>
        )}
        {isFetching && (
          <span>
            <i className={`${styles.miniPad} mdi mdi-loading mdi-spin`} />{' '}
            Loading...
          </span>
        )}
      </div>
      {fieldCount !== 0 && (
        <PaginationButtons
          currentPage={currentPage}
          totalPages={totalPages}
          pageSize={limit}
          scrollToTop={props.footer}
        />
      )}
    </div>
  );
}

export function PaginationButtons({
  currentPage,
  totalPages,
  pageSize,
  scrollToTop,
}: {
  currentPage: number;
  totalPages: number;
  pageSize: number;
  scrollToTop?: boolean;
}) {
  const pages = [-2, -1, 0, 1, 2]
    .map((i) => currentPage + i)
    .filter((p) => p > 0 && p <= totalPages);
  const dispatch = useDispatch();
  const setPage = useMemo(
    () => (page) => {
      if (scrollToTop) {
        window.scroll(0, 0);
      }
      dispatch(updateSelectedFiltersAction({ offset: pageSize * (page - 1) }));
    },
    [pageSize, scrollToTop, dispatch],
  );
  return (
    <div className={styles.paginationButtons}>
      <Button
        data-testid="previous-button"
        disabled={currentPage === 1}
        onClick={() => setPage(currentPage - 1)}
        link
      >
        <i className="mdi mdi-chevron-left" />
      </Button>
      {pages[0] !== 1 && <span className={styles.miniPad}>...</span>}
      {pages.map((page) => (
        <Button
          key={page}
          data-testid={`page-${page}`}
          disabled={page === currentPage}
          onClick={() => setPage(page)}
          link
        >
          <span className={styles.miniPad}>{page}</span>
        </Button>
      ))}
      {pages[-1] !== totalPages && <span className={styles.miniPad}>...</span>}
      <Button
        data-testid="next-button"
        disabled={currentPage === totalPages}
        onClick={() => setPage(currentPage + 1)}
        link
      >
        <i className="mdi mdi-chevron-right" />
      </Button>
    </div>
  );
}
