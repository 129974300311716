import { call, put, select } from 'redux-saga/effects';

import { RootState } from 'src/store/reducers';
import { toSerializableError } from 'src/utils/errors';
import { takeLatestSafe } from 'src/utils/sagas';

import {
  FETCH_FIELDS,
  fetchFieldsErrorAction,
  fetchingFieldsAction,
  updateFieldsDataAction,
} from '../actions/fields';
import {
  FETCH_REFERENTIAL,
  SWITCH_ROOT_FILTER,
  UPDATE_CREATED_DATE_RANGE,
  UPDATE_FILTER,
  UPDATE_FILTERS,
  UPDATE_SEARCH_QUERY,
  UPDATE_SELECTED_KIND,
  UPDATE_SELECTED_LANG,
  fetchReferentialAction,
  updateReferentialAction,
} from '../actions/filters';
import { fetchFields, fetchReferential } from '../api';
import type {
  FieldsResponseType,
  ReferentialType,
  SelectedFiltersType,
} from '../types';

export function* fieldsSaga() {
  yield takeLatestSafe(
    [
      FETCH_FIELDS,
      UPDATE_FILTER,
      UPDATE_FILTERS,
      UPDATE_SELECTED_KIND,
      UPDATE_SELECTED_LANG,
      UPDATE_CREATED_DATE_RANGE,
      UPDATE_SEARCH_QUERY,
      SWITCH_ROOT_FILTER,
    ],
    fetchFieldsSaga,
  );
  yield takeLatestSafe(FETCH_REFERENTIAL, fetchReferentialSaga);
}

function* fetchFieldsSaga() {
  try {
    yield put(fetchingFieldsAction(true));
    const filters: SelectedFiltersType = yield select(
      (state: RootState) => state.datamodelFilters.selectedFilters,
    );
    const response: FieldsResponseType = yield call(fetchFields, filters);
    yield put(updateFieldsDataAction(response));
  } catch (error) {
    console.error(error);
    yield put(fetchFieldsErrorAction(toSerializableError(error)));
  } finally {
    yield put(fetchingFieldsAction(false));
  }
}

function* fetchReferentialSaga(
  action: ReturnType<typeof fetchReferentialAction>,
) {
  try {
    const selected = yield select(
      (state: RootState) => state.datamodelFields.referential.selected,
    );
    const lang: string = yield select(
      (state: RootState) => state.datamodelFilters.selectedFilters.lang.key,
    );
    let recipientId: number;
    if (action.payload) {
      recipientId = action.payload;
    } else {
      // Use the org of the user.
      recipientId = yield select(
        (state: RootState) => state.user.data?.belongsTo[0].id,
      );
    }
    const referentials: ReferentialType[] = yield call(
      fetchReferential,
      selected,
      lang,
      recipientId,
    );
    yield put(updateReferentialAction(referentials));
  } catch (error) {
    console.error(error);
    yield put(updateReferentialAction([]));
  }
}
