import classNames from 'classnames';
import { ReactNode, useCallback, useEffect, useState } from 'react';

import { Modal } from 'src/components/modal';
import { useDispatch, useSelector } from 'src/store';

import {
  fetchReferentialAction,
  resetReferentialAction,
} from '../../../actions/filters';
import { RecipientType } from '../../../types';

import { RecipientSelect } from './recipients';
import styles from './referential-modal.module.scss';

export function DatamodelReferentialModal() {
  const dispatch = useDispatch();
  const { selected, isFetching, list } = useSelector(
    (state) => state.datamodelFields.referential,
  );

  // Recipient selection.
  const [selectedRecipient, selectRecipient] = useState<RecipientType | null>();
  const onSelectRecipient = useCallback(
    (recipient?: RecipientType | null) => {
      selectRecipient(recipient || null);
      dispatch(fetchReferentialAction(recipient?.key || null));
    },
    [dispatch],
  );
  useEffect(() => {
    if (selected && selectedRecipient === undefined) {
      if (selected.path && selected.specificRecipients.length > 0) {
        onSelectRecipient(selected.specificRecipients[0]);
      } else {
        // Non-specific or retailer case.
        onSelectRecipient(null);
      }
    } else if (!selected && selectedRecipient !== undefined) {
      // Reset selected on reset store.
      selectRecipient(undefined);
    }
  }, [selected, selectedRecipient, onSelectRecipient, selectRecipient]);

  let body: ReactNode;
  if (isFetching) {
    body = (
      <div className={styles.spinner}>
        <i
          className="mdi mdi-loading mdi-spin"
          data-testid="referential-spinner"
        />
      </div>
    );
  } else if (!list?.length) {
    body = <div>This referential is empty or could not be found.</div>;
  } else {
    body = (
      <div className={styles.referentialTable}>
        <div
          className={classNames(
            styles.referentialRow,
            styles.referentialRowHeader,
          )}
        >
          <div className={styles.referentialCell}>Code</div>
          <div className={styles.referentialCell}>Label</div>
          <div className={styles.referentialCell}>Description</div>
          <div className={styles.referentialCell}>GDSN code</div>
        </div>
        {list.map((referential) => (
          <div
            key={referential.id}
            className={styles.referentialRow}
            data-testid="referential-row"
          >
            <div className={styles.referentialCell}>{referential.code}</div>
            <div className={styles.referentialCell}>{referential.label}</div>
            <div className={styles.referentialCell}>
              {referential.description ? (
                <span
                  className={styles.description}
                  data-testid="referential-description"
                >
                  {referential.description}
                </span>
              ) : null}
            </div>
            <div className={styles.referentialCell}>
              {referential.data &&
              'GDSN3.1' in referential.data &&
              referential.data['GDSN3.1']
                ? Array.isArray(referential.data['GDSN3.1'])
                  ? referential.data['GDSN3.1'].join(', ')
                  : referential.data['GDSN3.1']
                : null}
            </div>
          </div>
        ))}
      </div>
    );
  }

  return selected ? (
    <Modal
      title={selected.slug}
      onClose={() => {
        dispatch(resetReferentialAction());
      }}
    >
      {selectedRecipient && (
        <div className={styles.recipientSelect}>
          For
          <RecipientSelect
            recipients={selected.specificRecipients}
            onSelect={onSelectRecipient}
            selectedRecipient={selectedRecipient}
          />
        </div>
      )}
      {body}
    </Modal>
  ) : null;
}
