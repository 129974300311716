import type { Immutable } from 'immer';
import type { BasicDataNode } from 'rc-tree';

export interface DataNode extends BasicDataNode {
  children?: DataNode[];
  key: React.Key;
  title?: string;
}

const getSubTree = <T>(
  item: T,
  childrenKeyOrGetter:
    | string
    | ((item: T) => undefined | T[] | ReadonlyArray<T>),
) =>
  typeof childrenKeyOrGetter === 'function'
    ? childrenKeyOrGetter(item)
    : item[childrenKeyOrGetter];

export const mapTree = <T, R>(
  tree: T[] | ReadonlyArray<T>,
  childrenKeyOrGetter:
    | string
    | ((item: T) => undefined | T[] | ReadonlyArray<T>),
  callback: (item: T, children?: R[]) => R,
): R[] =>
  tree.map<R>((item: T) => {
    let children: undefined | R[];
    const subTree = getSubTree(item, childrenKeyOrGetter);
    if (subTree) {
      children = mapTree<T, R>(subTree as T[], childrenKeyOrGetter, callback);
    }
    return callback(item, children);
  });

export const flatMapTree = <T, R>(
  tree: T[] | ReadonlyArray<T>,
  childrenKeyOrGetter:
    | string
    | ((item: T) => undefined | T[] | ReadonlyArray<T>),
  callback: (item: T) => R,
): R[] =>
  tree.flatMap((item: T) => {
    let children: undefined | R[];
    const subTree = getSubTree(item, childrenKeyOrGetter);
    const itemResult = callback(item);
    if (subTree) {
      children = flatMapTree<T, R>(
        subTree as T[],
        childrenKeyOrGetter,
        callback,
      );
      if (children) {
        return [itemResult].concat(children);
      }
    }
    return [itemResult];
  });

export const traverseTree = <T>(
  tree: T[] | ReadonlyArray<T>,
  childrenKey: string,
  callback: (item: T) => void,
) => {
  for (const item of tree) {
    callback(item);
    if (item[childrenKey]) {
      traverseTree(item[childrenKey], childrenKey, callback);
    }
  }
};

export const toTreeOptions = <T, R extends DataNode>(
  data: T[],
  {
    getId = (dataItem: any) => dataItem.id,
    getLabel = (dataItem: any) => dataItem.label,
    getChildren = (dataItem: any) => dataItem.children,
  }: {
    getId?: (dataItem: T) => string | number;
    getLabel?: (dataItem: T) => string;
    getChildren?: (dataItem: T) => T[] | ReadonlyArray<T> | undefined;
  } = {},
): R[] =>
  mapTree<T, R>(data, getChildren, (item, children) => {
    const option = {
      key: getId(item),
      title: getLabel(item),
    } as R;
    if (children) {
      option.children = children;
    }
    return option;
  });

export const findInTree = <T>(
  tree: T[] | Immutable<T[]>,
  predicate: (item: T | Immutable<T>) => boolean,
  {
    getChildren = (dataItem: any) => dataItem.children,
  }: {
    getChildren?: (dataItem: T | Immutable<T>) => any;
  } = {},
): T | Immutable<T> | null => {
  const queue = [...tree];
  while (queue.length) {
    const item = queue.shift();
    if (item) {
      if (predicate(item)) {
        return item;
      }
      const children = getChildren(item);
      if (children) {
        queue.push(...children);
      }
    }
  }
  return null;
};
