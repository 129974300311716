import { useSelector } from 'src/store';

import { DatamodelField } from './field';
import styles from './fields.module.scss';
import { DatamodelReferentialModal } from './referential-modal';

export function DatamodelFields() {
  const fields = useSelector((state) => state.datamodelFields.list);
  const packagingTypes = useSelector(
    (state) => state.datamodelFields.entities.packagingTypes,
  );
  return (
    <>
      <div className={styles.fields}>
        {fields.map((field) => (
          <DatamodelField
            key={field.id}
            data={field}
            packagingTypes={packagingTypes}
          />
        ))}
      </div>
      <DatamodelReferentialModal />
    </>
  );
}
