import { call, put } from 'redux-saga/effects';

import { Authentication } from '@alkem/sdk-dashboard';

import { resetAppAction } from 'src/store/actions';
import { takeLatestSafe } from 'src/utils/sagas';

import { showLoginAction } from '../actions/login';
import { FETCH_LOGOUT } from '../actions/logout';
import { fetchLogout } from '../api';

export function* logoutSaga() {
  yield takeLatestSafe(FETCH_LOGOUT, fetchLogoutSaga);
}

function* fetchLogoutSaga() {
  try {
    yield call(fetchLogout);
  } catch (error) {
    console.error(error);
  }
  Authentication.clearTokensAfterLogout();
  yield put(resetAppAction());
  yield put(showLoginAction(true));
}
