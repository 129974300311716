import { createReducer } from '@reduxjs/toolkit';

import {
  downloadingAllJsonSamplesAction,
  downloadingAllReferentialsAction,
  downloadingAllXmlSamplesAction,
} from '../actions/downloads';
import type { DatamodelDownloadsStateImmutable } from '../types';

export const datamodelDownloadsReducerKey = 'datamodelDownloads';

export const datamodelDownloadsInitialState: DatamodelDownloadsStateImmutable =
  {
    isDownloadingReferentials: false,
    isDownloadingJsonSamples: false,
    isDownloadingXmlSamples: false,
  };

export const datamodelDownloadsReducer =
  createReducer<DatamodelDownloadsStateImmutable>(
    datamodelDownloadsInitialState,
    (builder) => {
      builder
        .addCase(downloadingAllReferentialsAction, (state, action) => {
          state.isDownloadingReferentials = action.payload;
        })
        .addCase(downloadingAllJsonSamplesAction, (state, action) => {
          state.isDownloadingJsonSamples = action.payload;
        })
        .addCase(downloadingAllXmlSamplesAction, (state, action) => {
          state.isDownloadingXmlSamples = action.payload;
        });
    },
  );
